import { type orderType } from '~/factories/api/warehouse/picking-dashboard/types'
import type * as T from '~/features/warehouse/labels/carrier-labels/api/types'

export const initialState_warehouse_pickingDashboard: initialStateType_warehouse_pickingDashboard =
  {
    tab: 'shipments',
    // Orders
    orders: [],
    order: null,
    orderModal: false,
    playSound: false,
    sortByShipping: {
      label: 'All',
      value: 'all'
    },
    movingOrder: {
      moving: false,
      status: null,
      order_id: null
    },
    showOrderByPriority: false,
    // Warehouse locations
    warehouseLocations: null,
    warehouseLocation: ['FIN_MSTR'],
    // Options
    showBarcode: null,
    racksOnly: false,
    showPulse: false,
    printedDeliveryNotes: [],
    printedShipmentLabels: [],

    /**
     * Shipments end
     */
    carriers: [],
    carrier: null,
    carrierServices: [],
    carrierService: null,
    quoteDialog: false,

    modals: {
      carrier_labels: false,
      dbhForm: false
    }
  }

type initialStateType_warehouse_pickingDashboard = {
  tab: string
  // Orders
  orders: orderType[]
  order: orderType | null
  orderModal: boolean
  showPulse: boolean
  sortByShipping: {
    label: string
    value: string
  }
  movingOrder: {
    moving: boolean
    status: string | null
    order_id: string | null
  }
  showOrderByPriority: boolean
  racksOnly: boolean
  showBarcode: {
    order_id: string
  } | null
  printedDeliveryNotes: string[]
  printedShipmentLabels: string[]
  warehouseLocations: T.warehouseGetWerehouseLocationsResponse | null
  warehouseLocation: string[]
  carriers: carrierType[]
  carrier: carrierType | null
  carrierServices: {
    id: string
    services: never[]
  }[]
  carrierService: servicesType | null | undefined
  playSound: boolean
  quoteDialog: boolean

  modals: {
    carrier_labels: boolean
    dbhForm: boolean
  }
}

export type carrierType = {
  id: string
  name: string
  services: servicesType[]
  code: string
}

export type servicesType = {
  id: string
  name: string
  service: string
  total: number | string
}
